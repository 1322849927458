export function Home(props) {
    return(
        <section id="dashboard-analytics">
            <div className="row">
                <div className="col-sm-12">
                    Welcome to the Dashboard
                </div>
            </div>
        </section>
       
    )
}