import { Fragment } from "react";
import { Redirect, Route } from "react-router-dom";
import { Modules } from "../modules";

export function PrivateRoute(props) {
    const currentPathname = props.location.pathname;

    return(
        <Fragment>
             { !Modules.Auth.isLoggedIn() ?
            <Redirect to={`/auth/login${currentPathname ? '?from=' + currentPathname : ''}`} from='/hello'/>:
            <Route {...props} />
         }
        </Fragment>
    )
}